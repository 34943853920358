<template>
  <v-container>
    <v-row class="mt-6">
      <!-- TITULO -->
      <v-col class="text-center pb-0" cols="12">
        <span class="h3 semiBold">{{
          loading
            ? "Cargando datos del cliente..."
            : editMode
            ? "Editar datos del cliente"
            : "Registro del cliente"
        }}</span>
      </v-col>

      <!-- CONTENIDO -->
      <v-col cols="12" md="8" offset-md="2">
        <v-form ref="clientForm">
          <v-card
            class="light br-12 pa-6 ma-6"
            elevation="0"
            outlined
            :loading="loading">
            <v-card-text>
              <v-row>
                <v-col v-if="isEditUser">
                  <p class="title-edit-user">Tipo de documento</p>
                  <v-radio-group
                    v-if="country === countries.PE"
                    v-model="selected_document"
                    row
                    :rules="[rules.required]">
                    <v-radio
                      v-for="(item, index) in tipos_documentos"
                      :label="item.descripcion"
                      :value="item.codigo"
                      :key="index"></v-radio>
                  </v-radio-group>
                  <v-radio-group
                    v-else
                    v-model="selected_document"
                    row
                    :rules="[rules.required]">
                    <v-radio
                      v-for="(item, index) in tipos_personas"
                      :label="item.descripcion"
                      :value="item.codigo"
                      :key="index"></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>
              <v-row>
                <!-- RUT -->
                <v-col cols="12" md="6" class="pb-0">
                  <!-- == Descomentar en el caso que se desee modificar el rut == -->
                  <!-- <v-text-field
                    v-if="tipoRut"
                    label="RUT*"
                    placeholder="Ingrese el RUT de cliente o compañía."
                    v-model="form.rut_contacto"
                    outlined
                    v-mask="rutMask"
                    :hint="'RUT actual: ' + rut"
                    persistent-hint
                    dense
                    name="editCompanyRut"
                    :rules="[rules.required, rules.rut, rules.isValid]"
                  ></v-text-field> -->
                  <!-- ==  == -->

                  <LabelComponent
                    :text="
                      country === countries.CL
                        ? selected_document === 'NAT'
                          ? 'N° documento '
                          : 'N° documento comercial'
                        : selected_document !== 'CI'
                        ? 'N° DNI'
                        : 'N° RUC'
                    " />
                  <v-chip
                    label
                    outlined
                    large
                    color="primary"
                    style="width: 100%; height: 40px"
                    :disabled="editMode">
                    {{ rut | rutFormat }}
                  </v-chip>
                </v-col>

                <!-- RAZON SOCIAL -->
                <v-col
                  cols="12"
                  md="6"
                  v-if="
                    (country === countries.CL && selected_document === 'JUR') ||
                    (country === countries.PE && selected_document !== 'DNI')
                  "
                  class="pb-0">
                  <LabelComponent text="Razón Social*" />
                  <v-text-field
                    outlined
                    v-model="form.razon_social"
                    dense
                    :rules="[rules.required]"
                    name="editCompanySocialReazon">
                  </v-text-field>
                </v-col>
                <!-- NOMBRE -->
                <template
                  v-if="
                    country === countries.CL ||
                    (country === countries.PE &&
                      ['DNI', 'RUC'].includes(selected_document)) // TODO: verificar codigo de RUC cuando este disponible en servicios
                  ">
                  <v-col :cols="this.tipo === 'JUR' ? 4 : 6" class="pb-0">
                    <LabelComponent text="Nombre*" />
                    <v-text-field
                      name="editUserName"
                      autocomplete="given-name"
                      v-model="form.nombre"
                      outlined
                      dense
                      :rules="[rules.required]"></v-text-field>
                  </v-col>
                  <!-- APELLIDO 1 -->
                  <v-col cols="4" :md="tipo == 'NAT' ? 6 : 4">
                    <LabelComponent text="Primer apellido*" />
                    <v-text-field
                      name="editUserLastName"
                      autocomplete="family-name"
                      v-model="form.primer_apellido"
                      outlined
                      dense
                      :rules="[rules.required]"></v-text-field>
                  </v-col>

                  <!-- APELLIDO 2 -->
                  <v-col cols="4" :md="tipo == 'NAT' ? 6 : 4">
                    <LabelComponent text="Segundo apellido*" />
                    <v-text-field
                      name="editUserSecondLastName"
                      v-model="form.segundo_apellido"
                      outlined
                      dense
                      :rules="[rules.required]"></v-text-field>
                  </v-col>

                  <!-- FECHA -->
                  <v-col class="col-edit-user" cols="12" md="6">
                    <LabelComponent text="Fecha de nacimiento*" />
                    <v-text-field
                      v-model="form.fecha_nacimiento"
                      :rules="[rules.required, rules.date, rules.date18]"
                      placeholder="dd/mm/yyyy"
                      v-mask="dateMask"
                      outlined
                      dense
                      name="editUserBirthDate"></v-text-field>
                  </v-col>

                  <!-- GENERO -->
                  <v-col class="col-edit-user" cols="12" md="6">
                    <LabelComponent text="Género*" />
                    <v-select
                      :items="genreList"
                      v-model="form.genero"
                      dense
                      outlined
                      autocomplete="sex"
                      append-icon="arrow_drop_down"
                      name="editUserGender"
                      :rules="[rules.required]">
                      <template v-slot:append>
                        <Icon name="expand_more" size="25px" />
                      </template>
                    </v-select>
                  </v-col>
                </template>
                <template
                  v-if="
                    country === countries.PE &&
                    !['DNI', 'RUC'].includes(selected_document)
                  ">
                  <v-col cols="12" md="6" class="pb-0">
                    <LabelComponent text="Giro*" />
                    <v-text-field
                      outlined
                      v-model="form.giro"
                      dense
                      :rules="[rules.required]"
                      name="editCompanySocialReazon">
                    </v-text-field>
                  </v-col>
                  <v-col class="col-edit-user" cols="12" md="6">
                    <LabelComponent text="Actividad comercial*" />
                    <v-text-field
                      outlined
                      v-model="form.actividad_comercial"
                      dense
                      :rules="[rules.required]"
                      name="editCompanySocialReazon">
                    </v-text-field>
                  </v-col>
                </template>

                <!-- TELEFONO -->
                <v-col class="col-edit-user" cols="12" md="6">
                  <LabelComponent text="Teléfono*" />
                  <v-text-field
                    v-model="form.telefono"
                    outlined
                    dense
                    name="editUserTelefono"
                    :rules="[rules.required, rules.telefono]"></v-text-field>
                  <!-- <TextFieldPhone
                    :text="form.telefono"
                    @input="form.telefono = $event"
                    :rules="[rules.required, rules.telefono]"
                    name="editUserTelefono" /> -->
                </v-col>

                <!-- EMAIL -->
                <v-col class="col-edit-user" cols="12" md="6">
                  <LabelComponent text="Correo*" />
                  <v-text-field
                    v-model="form.email"
                    outlined
                    dense
                    name="editUserEmail"
                    autocomplete="email"
                    :rules="[rules.required, rules.email]"></v-text-field>
                </v-col>

                <!-- DEPARTAMENTO -->
                <v-col
                  class="col-edit-user"
                  cols="12"
                  v-if="country === countries.PE">
                  <LabelComponent text="Departamento" />
                  <v-autocomplete
                    v-model="form.region"
                    :items="regiones"
                    item-text="nombre"
                    item-value="codigo"
                    return-object
                    :rules="[rules.required]"
                    name="editUserComuna"
                    outlined
                    dense
                    :loading="loadingComuna">
                    <template v-slot:append>
                      <Icon name="expand_more" size="25px" />
                    </template>
                  </v-autocomplete>
                </v-col>
                <!-- PROVINCIA -->
                <v-col
                  class="col-edit-user"
                  cols="12"
                  md="6"
                  v-if="country === countries.PE">
                  <LabelComponent text="Provincia" />
                  <v-autocomplete
                    v-model="form.provincia"
                    :items="editMode ? provincias : filteredProvinces"
                    item-text="nombre"
                    item-value="codigo"
                    :disabled="!form.region"
                    return-object
                    :rules="[rules.required]"
                    name="editUserComuna"
                    outlined
                    dense
                    :loading="loadingComuna">
                    <template v-slot:append>
                      <Icon name="expand_more" size="25px" />
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col
                  class="col-edit-user"
                  cols="12"
                  md="6"
                  v-if="country === countries.PE">
                  <LabelComponent text="Distrito" />
                  <v-autocomplete
                    v-model="form.comuna"
                    :items="editMode ? comunas : filteredCommunes"
                    item-text="nombre"
                    item-value="codigo"
                    return-object
                    :disabled="!form.provincia"
                    :rules="[rules.required]"
                    name="editUserComuna"
                    outlined
                    dense
                    :loading="loadingComuna">
                    <template v-slot:append>
                      <Icon name="expand_more" size="25px" />
                    </template>
                  </v-autocomplete>
                </v-col>

                <!-- COMUNA -->
                <v-col
                  class="col-edit-user"
                  cols="12"
                  v-if="country === countries.CL">
                  <LabelComponent text="Comuna*" />
                  <v-autocomplete
                    v-model="form.comuna"
                    :items="comunas"
                    return-object
                    item-text="nombre"
                    item-value="codigo"
                    :rules="[rules.required]"
                    name="editUserComuna"
                    autocomplete="street-address"
                    outlined
                    dense
                    :loading="loadingComuna">
                    <template v-slot:append>
                      <Icon name="expand_more" size="25px" />
                    </template>
                  </v-autocomplete>
                </v-col>

                <!-- EXTRA -->
                <!-- <v-col cols="12" class="py-0">
                  <v-slide-y-transition mode="out-in">
                    <div v-if="showOther">
                      <LabelComponent text="Información extra" />
                      <v-textarea
                        @click:append="showOther = false"
                        name="otro"
                        v-model="form.otro"
                        outlined
                        append-icon="mdi-close-circle-outline"
                        @click:clear="showOther = false"></v-textarea>
                    </div>
                    <v-checkbox
                      v-else
                      label="Información extra"
                      v-model="showOther"
                      :true-value="true"
                      :false-value="false"></v-checkbox>
                  </v-slide-y-transition>
                </v-col> -->
              </v-row>
            </v-card-text>
          </v-card>
          <v-col>
            <div class="d-flex justify-center gx-6">
              <v-btn
                color="primary"
                outlinedco
                rounded
                class="text-none"
                @click="goBack"
                width="140"
                :disabled="loadingForm"
                >Volver</v-btn
              >
              <v-btn
                color="primary"
                rounded
                width="160"
                class="ml-4 text-none"
                :loading="loadingForm"
                @click="save()"
                >{{ editMode ? "Guardar Cambios" : "Registrar" }}</v-btn
              >
            </div>
          </v-col>
        </v-form>
      </v-col>
    </v-row>

    <CreateSuccessModal
      v-model="dialog.createModal"
      :rut="rut"
      @close="goForward()"
      @continue="goForward()" />

    <EditSuccessModal
      v-model="dialog.editModal"
      :rut="rut"
      :name="
        tipo === 'NAT'
          ? `${form.nombre} ${form.primer_apellido}`
          : form.razon_social
      "
      :documentType="country === countries.CL ? 'RUT' : document"
      @close="dialog.editModal = false"
      @continue="goForward()" />
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
// import TextFieldPhone from "@/components/Inputs/TextFieldPhone";
import CreateSuccessModal from "@/components/Modal/Modal-New-Client.vue";
import EditSuccessModal from "@/components/Modal/Modal-Edit-user.vue";
import LabelComponent from "@/components/Inputs/Label.vue";
import { dateMask, rutMask } from "@/helpers/mask.js";
import { genreList } from "@/store/resources/constants.js";
import Icon from "@/components/Icon/Icon.vue";

import {
  validarFecha,
  validarEmail,
  validarRutFormat,
  validarRut,
  validarRutTipo,
  validarFecha18,
} from "@/store/resources/validate.js";
import { COUNTRIES } from "../../helpers/constants";

export default {
  props: {
    rut: {
      type: String,
      required: true,
      description: "RUT del cliente, parametro de cabecera.",
    },
    document: {
      type: String,
      required: false,
      description: "Tipo de documento",
    },
    isEditUser: {
      type: Boolean,
      requierd: false,
      default: false,
    },
  },
  name: "AddEditClientPage",
  data: () => ({
    countries: COUNTRIES,
    tipos_personas: JSON.parse(sessionStorage.getItem("tipo_personas")),
    tipos_documentos: JSON.parse(sessionStorage.getItem("tipo_documentos")),
    editMode: false,
    loading: false,
    loadingForm: false,
    loadingComuna: false,
    showOther: false,
    selected_document: null,
    dialog: {
      editModal: false,
      createModal: false,
    },
    tipo: "NAT",
    form: {
      razon_social: "",
      rut_contacto: "",
      nombre: "",
      primer_apellido: "",
      segundo_apellido: "",
      email: "",
      telefono: "",
      fecha_nacimiento: "",
      genero: "",
      otro: "",
      region: null,
      provincia: null,
      comuna: null,
      actividad_comercial: null,
      giro: null,
    },
    comunaList: [],
    rules: {
      required: (value) => !!value || "Campo requerido.",
      email: (value) => validarEmail(value) || "Correo invalido.",
      date: (value) => validarFecha(value) || "Formato de fecha incorrecto",
      date18: (value) => validarFecha18(value) || "Debe ser mayor de edad",
      telefono: (value) => value.length == 9 || "Teléfono inválido",
      rut: (value) => validarRutFormat(value) || "Formato de RUT invalido",
      isValid: (value) =>
        validarRut(value) || "No se ha logrado reconocer el RUT",
    },
  }),

  computed: {
    // ...mapGetters("Proposal", ["getCities"]),
    ...mapGetters("Internationalization", [
      "comunas",
      "regiones",
      "provincias",
      "tipoDocumentos",
      "tipoPersonas",
    ]),
    rutMask: () => rutMask,
    dateMask: () => dateMask,
    genreList: () => genreList,
    rutTipoCheck() {
      return validarRutTipo(this.rut);
    },
    country() {
      return this.$store.getters.getCountry;
    },

    filteredProvinces() {
      if (this.form.region) {
        return this.form.region.provincias;
      }
      return [];
    },
    filteredCommunes() {
      if (this.form.provincia) {
        return this.form.provincia.comunas;
      }
      return [];
    },
  },
  created() {
    this.setup();
    const document = this.$route.params.document;
    this.selected_document = document || "NAT";
  },

  methods: {
    ...mapActions("Users", [
      "createUserNatural",
      "createUserJuridico",
      "editUserNatural",
      "editUserJuridico",
      "setClient",
    ]),
    ...mapActions("Proposal", ["getLocations"]),
    ...mapActions("Internationalization", [
      "getLocations",
      "getTiposDocumentos",
    ]),

    setup() {
      this.setupClient();
    },

    setupClient() {
      this.loading = true;
      const documentType =
        this.selected_document ||
        JSON.parse(sessionStorage.getItem("client")).tipo_documento;
      this.setClient({ documentType, documentNumber: this.rut })
        .then((response) => {
          this.editMode = true;
          this.tipo = response.tipo_persona;
          this.setFormData(response);
        })
        .catch(() => {
          this.tipo = this.rutTipoCheck ? "JUR" : "NAT";
          this.form.rut_contacto = this.rut;
        })
        .finally(() => {
          this.loading = false;
        });
    },

    goBack() {
      if (!this.editMode) {
        this.$router.push({ name: "dashboard" });
      } else {
        this.goForward();
      }
    },
    goForward() {
      this.$router.push({
        name: "UserDetail",
        params: {
          rut: this.rut,
        },
      });
    },

    setFormData(data) {
      // Se establece la informacion previa del cliente
      this.form.nombre = data?.nombre ?? "";
      this.form.primer_apellido = data?.primer_apellido ?? "";
      this.form.segundo_apellido = data?.segundo_apellido ?? "";
      this.form.fecha_nacimiento =
        data?.fecha_nacimiento.split("/").join("") ?? "";
      this.form.genero = data?.genero ?? "";
      this.form.telefono = data?.datos_contacto?.telefono ?? "";
      this.form.email = data?.datos_contacto?.email || "";
      this.form.otro = data?.datos_contacto?.otro ?? "";
      // Establecer datos de la comuna
      this.form.comuna =
        this.comunas.find((comuna) => comuna._id === data?.comuna) ||
        this.comunas.find((comuna) => comuna.codigo === data?.comuna);
      data.comuna;
      this.form.region =
        this.regiones.find((region) => region._id === data?.region) ||
        data.region;
      this.form.provincia =
        this.provincias.find(
          (provincia) => provincia._id === data?.provincia
        ) || data.provincia;
      // Establecer razon social
      if (this.tipo === "JUR") {
        this.form.razon_social = data.razon_social;
      }
    },

    save() {
      let isValid = this.$refs.clientForm.validate();
      if (isValid) {
        this.loadingForm = true;
        const formData = {
          ...this.form,
          numero_documento: this.rut,
          cod_pais: this.country,
          giro: this.giro,
          actividad_comercial: this.actividad_comercial,
          tipo_documento:
            this.country === this.countries.CL ? "RUT" : this.selected_document,
        };

        let method;
        const dialog = this.editMode ? "editModal" : "createModal";

        const tipoPersona =
          this.country === this.countries.CL
            ? this.selected_document
            : ["DNI", "RUC"].includes(this.selected_document)
            ? "NAT"
            : "JUR";
        // Se elige el metodo de Editado/Guardado segun la condicion
        if (tipoPersona == "NAT") {
          if (!this.editMode) {
            method = this.createUserNatural;
          } else {
            method = this.editUserNatural;
          }
        } else {
          if (!this.editMode) {
            method = this.createUserJuridico;
          } else {
            method = this.editUserJuridico;
          }
        }
        // Se ejecuta el metodo
        method(formData)
          .then(() => {
            this.dialog[dialog] = true;
          })
          .catch((e) => {
            this.$store.commit("setSnackbar", {
              active: true,
              text: e.message,
              top: true,
              right: true,
              color: "warning",
            });
          })
          .finally(() => {
            this.loadingForm = false;
          });
      }
    },
  },

  components: {
    CreateSuccessModal,
    EditSuccessModal,
    LabelComponent,
    // TextFieldPhone,
    Icon,
  },
};
</script>

<style>
.title-edit-user {
  font-size: 14px;
}
.col-edit-user {
  margin-top: -20px;
  padding-bottom: 0;
}
</style>
