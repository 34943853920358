<template>
  <div class="text-center">
    <v-dialog
      :value="value"
      width="40%"
      height="80%"
      attach="#main-container"
      @click:outside="close()">
      <v-card class="py-4">
        <v-card-text>
          <div class="d-flex justify-space-between align-center">
            <span class="h5 semiBold">¡Los datos han sido editados!</span>
            <v-btn icon color="error" @click="close()">
              <Icon name="close" />
            </v-btn>
          </div>
          <div class="d-flex align-center mt-6 justify-center">
            <Icon name="manage_accounts" size="40px" class="icon-success" />
            <span class="p ml-6">
              Los datos del cliente <b> {{ name }}</b
              >, {{ documentType }}
              <span class="semiBold">
                {{ rut | rutFormat }}
              </span>
              han sido modificados satisfactoriamente.
            </span>
          </div>
          <div class="d-flex flex-column justify-center align-center my-6">
            <v-btn
              color="primary"
              class="text-none mt-4"
              width="214"
              height="41"
              rounded
              @click="goToClient()"
              >Ir a cotizar</v-btn
            >
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import Icon from "@/components/Icon/Icon.vue";

export default {
  name: "ClientEditSuccessModal",
  props: {
    value: {
      default: false,
      type: Boolean,
    },
    rut: {
      default: "",
      type: String,
    },
    name: {
      default: "",
      type: String,
    },
    documentType: {
      default: "",
      type: String,
    },
  },
  components: {
    Icon,
  },
  methods: {
    close() {
      this.$emit("close");
    },
    goToClient() {
      this.$emit("continue");
    },
  },
};
</script>
